<template>
  <div class="home">
    <div id="page-hero" class="pt-6 pb-2 py-md-12 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="12">
            <h1 class="display-2 mb-4">Demo white-label Platform</h1>
            <p>
              This shows how a customized white-label platform may look like and how you can customize the look and feel.
            </p>
            <p>
              Use sodisp to create engaging and beautiful virtual events and challenges!
              Sodisp is the only platform available with a complete focus on virtual events and challenges. All our features are aimed at providing best-in-class user experiences for participants and event organizers.
            </p>
            <p class="mb-0">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'events'}">Explore virtual events</v-btn>
              <v-btn class="mb-4" color="white" outlined :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-container class="mb-12">
      <v-row>
        <v-col>
          <h3 class="title">Upcoming virtual events</h3>
          <EventGrid :items="events" class=""></EventGrid>
        </v-col>
      </v-row>
    </v-container>
 
  </div>
  
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import eventService from "@/services/eventService";
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    EventGrid,
  },
  data() {
    return {
      tenant: tenant,
      events: null,
    };
  },
  async mounted() {
    this.events = (await eventService.upcoming()).data.data;

  }, 
};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style> 